
.tabs {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    }
    .tabs form {
    }
.filter{
    display: flex;
    flex-grow: 3;
    justify-content: space-between;

}
.date__control{
    margin-right: 2rem;
    min-width: 25rem;

}
.date__control input {
    width: 100%;
    border: 0.1rem solid #cbd4db;
    border-radius: 0.4rem;
    color: #6e7782;
    padding: 1rem 0.5rem 1rem 1.7rem;
    -webkit-transition: border-color 0.4s;
    -o-transition: border-color 0.4s;
    transition: border-color 0.4s;

}

.filter button{
    padding: 0.5rem 1.5rem;
}
.error-message{
    margin-top:15px;
    padding-bottom: 15px;
    color: #ee0200;
    font-weight: 600;
    font-size: 1.7rem;
    text-align: center;
}
